<template>
  <div class="main">
    <div class="select">
      <div class="select-one">
        <el-select v-model="value" placeholder="请选择" size="mini">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="select-one">
        <el-select v-model="value" placeholder="请选择" size="mini">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="content">
      <div class="main-left">
        <div class="auv-box marr0">
          <div class="title">无人机一号</div>
          <img src="../../../assets/flylog/auv.png" alt="" />
        </div>
        <div class="auv-box">
          <div class="title">无人机一号</div>
          <img src="../../../assets/flylog/auv.png" alt="" />
        </div>
        <div class="auv-box">
          <div class="title">无人机一号</div>
          <img src="../../../assets/flylog/auv.png" alt="" />
        </div>
      </div>
      <div class="main-right">
        <div class="video-date">
          <div class="day-week-month">
            <div class="day-btn">本日</div>
            <div class="day-btn">本周</div>
            <div class="day-btn">本月</div>
          </div>
          <div class="block">
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="—"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="task-box">
          <div class="task">
            <div class="task-unit">pcs</div>
            <div class="task-value">1230</div>
            <div class="task-title">总任务数量</div>
          </div>
          <div class="task-line">
            <img src="../../../assets/flylog/task-line.png" alt="" />
          </div>
          <div class="task">
            <div class="task-unit">次</div>
            <div class="task-value">1260</div>
            <div class="task-title">总飞行架次</div>
          </div>
          <div class="task-line">
            <img src="../../../assets/flylog/task-line.png" alt="" />
          </div>
          <div class="task">
            <div class="task-unit">次</div>
            <div class="task-value">1260</div>
            <div class="task-title">总飞行里程</div>
          </div>
          <div class="task-line">
            <img src="../../../assets/flylog/task-line.png" alt="" />
          </div>
          <div class="task">
            <div class="task-unit">h</div>
            <div class="task-value">1260</div>
            <div class="task-title">总飞行时长</div>
          </div>
          <div class="task-line">
            <img src="../../../assets/flylog/task-line.png" alt="" />
          </div>
          <div class="task">
            <div class="task-unit">pcs</div>
            <div class="task-value">1260</div>
            <div class="task-title">总照片数量</div>
          </div>
          <div class="task-line">
            <img src="../../../assets/flylog/task-line.png" alt="" />
          </div>
          <div class="task">
            <div class="task-unit">次</div>
            <div class="task-value">1260</div>
            <div class="task-title">ai预警总次数</div>
          </div>
        </div>
        <div class="select-task">
          <el-select v-model="value1" placeholder="任务名称" size="mini">
            <el-option
              v-for="item in optionsTask"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="table-list">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            stripe
            max-height="430"
          >
            <el-table-column
              prop="flightTime"
              label="飞行架次号(次)"
            ></el-table-column>
            <el-table-column prop="taskName" label="任务名称"></el-table-column>
            <el-table-column prop="flightKm" label="飞行里程数(km)">
            </el-table-column>
            <el-table-column prop="flightHours" label="飞行时长">
            </el-table-column>
            <el-table-column prop="pictrue" label="取证上传图片数">
            </el-table-column>
            <el-table-column prop="warningTime" label="AI预警数">
            </el-table-column>
            <el-table-column prop="historyRecord" label="历史记录回放">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        {
          value: "1",
          label: "海宁铁桥派出所",
        },
        {
          value: "2",
          label: "海宁铁桥派出所",
        },
        {
          value: "3",
          label: "海宁铁桥派出所",
        },
      ],
      value: "",
      optionsTask: [
        {
          value: "1",
          label: "任务一",
        },
        {
          value: "2",
          label: "任务二",
        },
        {
          value: "3",
          label: "任务三",
        },
      ],
      value1: "",
      tableData: [
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
        {
          flightTime: "2016-05-02",
          taskName: "王小虎",
          flightKm: 1236,
          flightHours: 6,
          pictrue: "暂无",
          warningTime: 34,
          historyRecord: "暂无",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 24px 22px 24px;
  .select {
    width: 100%;
    height: 28px;
    display: flex;
    margin-bottom: 5px;
    box-sizing: border-box;
    .select-one {
      width: 212px;
      height: 28px;
      margin-right: 32px;
    }
    .select-one ::v-deep .el-select {
      background: linear-gradient(
        270deg,
        rgba(0, 154, 255, 0) 0%,
        rgba(0, 154, 255, 0.5) 100%
      );
      border-radius: 2px;
    }
    .select-one ::v-deep .el-input__inner {
      background: linear-gradient(
        270deg,
        rgba(0, 154, 255, 0) 0%,
        rgba(0, 154, 255, 0.5) 100%
      );
      border: none;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      border-left: 2px solid #009aff;
    }
  }
  .content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .main-left {
      width: 378px;
      height: calc(100% - 53px);
      background: url(~@/assets/flylog/box-left-bg.png) no-repeat;
      background-size: 378px 100%;
      padding-top: 20px;
      .auv-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        .title {
          margin-bottom: 8px;
          width: 357px;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 32px;
          background: url(~@/assets/flylog/title-bg.png) no-repeat;
          background-size: 357px 32px;
          text-align: center;
        }
      }
      .marr0 {
        margin-top: 0px;
      }
    }
    .main-right {
      width: 1482px;
      height: calc(100% - 33px);
      background: url(~@/assets/flylog/box-right.png) no-repeat;
      background-size: 1482px 100%;
      padding: 24px 16px 0px 24px;
      box-sizing: border-box;
      .video-date {
        display: flex;
        align-items: center;
        .day-week-month {
          display: flex;
          width: 144px;
          height: 28px;
          .day-btn {
            width: 45px;
            height: 28px;
            border-radius: 2px;
            border: 1px solid #2c6cef;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e0e6f2;
            line-height: 28px;
            text-align: center;
          }
        }
        .block {
          width: 256px;
          height: 28px;
        }
        .block ::v-deep .el-date-editor {
          width: 256px;
          height: 28px;
          background: rgba(12, 34, 67, 0.5);
          border: 1px solid #009aff;
          .el-range-input {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }
          .el-range-separator {
            color: #9fc4ff;
          }
          .el-range__icon {
            line-height: 1px;
          }
        }
      }
      .task-box {
        width: 100%;
        height: 186px;
        margin-top: 37px;
        display: flex;
        justify-content: center;
        .task {
          width: 112px;
          height: 186px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .task-unit {
            width: 112px;
            height: 112px;
            background: url(~@/assets/flylog/unit-bg.png) no-repeat;
            background-size: 112px 112px;
            text-align: center;
            line-height: 112px;
            font-family: WeiRuanYaHei-Regular;
            font-size: 30px;
            color: #4bc5ff;
          }
          .task-value {
            // width: 56px;
            height: 30px;
            font-size: 30px;
            font-family: D-DIN-Bold, D-DIN;
            font-weight: bold;
            color: #a2c4fd;
            line-height: 33px;
            text-align: center;
            margin-top: 14px;
          }
          .task-title {
            // width: 80px;
            height: 22px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6a99e9;
            line-height: 22px;
            margin-top: 8px;
          }
        }
        .task-line {
          width: 12px;
          height: 102px;
          margin: 0px 64px;
          img {
            width: 12px;
            height: 102px;
          }
        }
      }
      .select-task {
        width: 176px;
        height: 36px;
        margin-top: 34px;
        margin-bottom: 17px;
        // background: rgba(3, 24, 49, 0.5);
        // border: 1px solid #009aff;
        ::v-deep .el-input {
          //   width: 176px;
          //   height: 36px;
          background: rgba(3, 24, 49, 0.5);
          //   border: 1px solid #009aff;
        }
        ::v-deep .el-select .el-input__inner {
          width: 176px;
          height: 36px;
          background: rgba(3, 24, 49, 0.5);
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9fc4ff;
          line-height: 22px;
          border: 1px solid #009aff;
        }
      }
      .table-list {
        ::v-deep .el-table .cell {
          text-align: center;
          color: #93b7f1;
        }
        /*最外层透明*/
        ::v-deep .el-table,
        ::v-deep .el-table__expanded-cell {
          background-color: transparent;
          border: none;
        }
        /* 删除表格下最底层的横线 */
        // ::v-deep .el-table::before {
        //   left: 0;
        //   bottom: 0;
        //   width: 100%;
        //   height: 0px;
        // }

        /* 表格内背景颜色 */
        ::v-deep .el-table th,
        ::v-deep .el-table tr,
        ::v-deep .el-table td {
          background-color: transparent;
          border: 1px dashed #ccc;
        }
      }
    }
  }
}
</style>
